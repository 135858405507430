import React from 'react'
import './ReviewCard.css'
import blog3 from '../../../../assets/images/test.jfif'
const ReviewCard = () => {
  return (
    <div className="ReviewCard">
      <div className="ReviewCard_img">
        <img src={blog3} alt="" />
      </div>
      <div className="ReviewCard_content">
        <h4>عبدالرحمن الزيات</h4>
        <p>
          هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا
          النص من مولد النص العربى،{" "}
        </p>
        <div className="rateStars">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M10.452 8.3535C10.3009 8.49991 10.2315 8.71166 10.2659 8.91933L10.7845 11.7893C10.8283 12.0326 10.7256 12.2787 10.522 12.4193C10.3225 12.5652 10.0571 12.5827 9.83953 12.466L7.25594 11.1185C7.16611 11.0707 7.06636 11.045 6.96428 11.0421H6.80619C6.75136 11.0502 6.69769 11.0677 6.64869 11.0946L4.06453 12.4485C3.93678 12.5127 3.79211 12.5354 3.65036 12.5127C3.30503 12.4473 3.07461 12.1183 3.13119 11.7712L3.65036 8.90125C3.68478 8.69183 3.61536 8.47891 3.46428 8.33016L1.35786 6.2885C1.18169 6.11758 1.12044 5.86091 1.20094 5.62933C1.27911 5.39833 1.47861 5.22975 1.71953 5.19183L4.61869 4.77125C4.83919 4.7485 5.03286 4.61433 5.13203 4.416L6.40953 1.79683C6.43986 1.7385 6.47894 1.68483 6.52619 1.63933L6.57869 1.5985C6.60611 1.56816 6.63761 1.54308 6.67261 1.52266L6.73619 1.49933L6.83536 1.4585H7.08094C7.30028 1.48125 7.49336 1.6125 7.59428 1.8085L8.88869 4.416C8.98203 4.60675 9.16344 4.73916 9.37286 4.77125L12.272 5.19183C12.517 5.22683 12.7218 5.396 12.8029 5.62933C12.8793 5.86325 12.8134 6.11991 12.6337 6.2885L10.452 8.3535Z"
              fill="#F6973F"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M10.452 8.3535C10.3009 8.49991 10.2315 8.71166 10.2659 8.91933L10.7845 11.7893C10.8283 12.0326 10.7256 12.2787 10.522 12.4193C10.3225 12.5652 10.0571 12.5827 9.83953 12.466L7.25594 11.1185C7.16611 11.0707 7.06636 11.045 6.96428 11.0421H6.80619C6.75136 11.0502 6.69769 11.0677 6.64869 11.0946L4.06453 12.4485C3.93678 12.5127 3.79211 12.5354 3.65036 12.5127C3.30503 12.4473 3.07461 12.1183 3.13119 11.7712L3.65036 8.90125C3.68478 8.69183 3.61536 8.47891 3.46428 8.33016L1.35786 6.2885C1.18169 6.11758 1.12044 5.86091 1.20094 5.62933C1.27911 5.39833 1.47861 5.22975 1.71953 5.19183L4.61869 4.77125C4.83919 4.7485 5.03286 4.61433 5.13203 4.416L6.40953 1.79683C6.43986 1.7385 6.47894 1.68483 6.52619 1.63933L6.57869 1.5985C6.60611 1.56816 6.63761 1.54308 6.67261 1.52266L6.73619 1.49933L6.83536 1.4585H7.08094C7.30028 1.48125 7.49336 1.6125 7.59428 1.8085L8.88869 4.416C8.98203 4.60675 9.16344 4.73916 9.37286 4.77125L12.272 5.19183C12.517 5.22683 12.7218 5.396 12.8029 5.62933C12.8793 5.86325 12.8134 6.11991 12.6337 6.2885L10.452 8.3535Z"
              fill="#F6973F"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M10.452 8.3535C10.3009 8.49991 10.2315 8.71166 10.2659 8.91933L10.7845 11.7893C10.8283 12.0326 10.7256 12.2787 10.522 12.4193C10.3225 12.5652 10.0571 12.5827 9.83953 12.466L7.25594 11.1185C7.16611 11.0707 7.06636 11.045 6.96428 11.0421H6.80619C6.75136 11.0502 6.69769 11.0677 6.64869 11.0946L4.06453 12.4485C3.93678 12.5127 3.79211 12.5354 3.65036 12.5127C3.30503 12.4473 3.07461 12.1183 3.13119 11.7712L3.65036 8.90125C3.68478 8.69183 3.61536 8.47891 3.46428 8.33016L1.35786 6.2885C1.18169 6.11758 1.12044 5.86091 1.20094 5.62933C1.27911 5.39833 1.47861 5.22975 1.71953 5.19183L4.61869 4.77125C4.83919 4.7485 5.03286 4.61433 5.13203 4.416L6.40953 1.79683C6.43986 1.7385 6.47894 1.68483 6.52619 1.63933L6.57869 1.5985C6.60611 1.56816 6.63761 1.54308 6.67261 1.52266L6.73619 1.49933L6.83536 1.4585H7.08094C7.30028 1.48125 7.49336 1.6125 7.59428 1.8085L8.88869 4.416C8.98203 4.60675 9.16344 4.73916 9.37286 4.77125L12.272 5.19183C12.517 5.22683 12.7218 5.396 12.8029 5.62933C12.8793 5.86325 12.8134 6.11991 12.6337 6.2885L10.452 8.3535Z"
              fill="#F6973F"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M10.452 8.3535C10.3009 8.49991 10.2315 8.71166 10.2659 8.91933L10.7845 11.7893C10.8283 12.0326 10.7256 12.2787 10.522 12.4193C10.3225 12.5652 10.0571 12.5827 9.83953 12.466L7.25594 11.1185C7.16611 11.0707 7.06636 11.045 6.96428 11.0421H6.80619C6.75136 11.0502 6.69769 11.0677 6.64869 11.0946L4.06453 12.4485C3.93678 12.5127 3.79211 12.5354 3.65036 12.5127C3.30503 12.4473 3.07461 12.1183 3.13119 11.7712L3.65036 8.90125C3.68478 8.69183 3.61536 8.47891 3.46428 8.33016L1.35786 6.2885C1.18169 6.11758 1.12044 5.86091 1.20094 5.62933C1.27911 5.39833 1.47861 5.22975 1.71953 5.19183L4.61869 4.77125C4.83919 4.7485 5.03286 4.61433 5.13203 4.416L6.40953 1.79683C6.43986 1.7385 6.47894 1.68483 6.52619 1.63933L6.57869 1.5985C6.60611 1.56816 6.63761 1.54308 6.67261 1.52266L6.73619 1.49933L6.83536 1.4585H7.08094C7.30028 1.48125 7.49336 1.6125 7.59428 1.8085L8.88869 4.416C8.98203 4.60675 9.16344 4.73916 9.37286 4.77125L12.272 5.19183C12.517 5.22683 12.7218 5.396 12.8029 5.62933C12.8793 5.86325 12.8134 6.11991 12.6337 6.2885L10.452 8.3535Z"
              fill="#F6973F"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M10.452 8.3535C10.3009 8.49991 10.2315 8.71166 10.2659 8.91933L10.7845 11.7893C10.8283 12.0326 10.7256 12.2787 10.522 12.4193C10.3225 12.5652 10.0571 12.5827 9.83953 12.466L7.25594 11.1185C7.16611 11.0707 7.06636 11.045 6.96428 11.0421H6.80619C6.75136 11.0502 6.69769 11.0677 6.64869 11.0946L4.06453 12.4485C3.93678 12.5127 3.79211 12.5354 3.65036 12.5127C3.30503 12.4473 3.07461 12.1183 3.13119 11.7712L3.65036 8.90125C3.68478 8.69183 3.61536 8.47891 3.46428 8.33016L1.35786 6.2885C1.18169 6.11758 1.12044 5.86091 1.20094 5.62933C1.27911 5.39833 1.47861 5.22975 1.71953 5.19183L4.61869 4.77125C4.83919 4.7485 5.03286 4.61433 5.13203 4.416L6.40953 1.79683C6.43986 1.7385 6.47894 1.68483 6.52619 1.63933L6.57869 1.5985C6.60611 1.56816 6.63761 1.54308 6.67261 1.52266L6.73619 1.49933L6.83536 1.4585H7.08094C7.30028 1.48125 7.49336 1.6125 7.59428 1.8085L8.88869 4.416C8.98203 4.60675 9.16344 4.73916 9.37286 4.77125L12.272 5.19183C12.517 5.22683 12.7218 5.396 12.8029 5.62933C12.8793 5.86325 12.8134 6.11991 12.6337 6.2885L10.452 8.3535Z"
              fill="#F6973F"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default ReviewCard