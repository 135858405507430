import React from 'react'
import { Container } from 'react-bootstrap'
import './CommonHotline.css'
const CommonHotline = () => {
  return (
    <div className="CommonLocation comonHotline">
      <Container>
        <h1>الخط اساخن</h1>
        <p>جميع ارقام الخطوط الساخنه لجميع الشركات</p>
      </Container>
    </div>
  )
}

export default CommonHotline