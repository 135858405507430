import React, { useEffect, useState } from "react";
import "./Questions.css";
import { Container } from "react-bootstrap";
import QuestionCard from "./QuestionCards/QuestionCard";
import QuestionCardDark from "./QuestionCards/QuestionCardDark";
const Questions = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="Questions">
      <div className="mainHead text-center">
        <h2>الاسئله الشائعه </h2>
        <p>
          نعرض لكم بعض من الأسئلة الشائعة التي وردت الينا والتي قد تخطر ببالك
        </p>
      </div>
      <div className="icontop">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="62"
          height="58"
          viewBox="0 0 62 58"
          fill="none"
        >
          <path
            d="M31 0L32.5047 29.9289L61.4338 22.1115L33.4347 32.7911L49.8091 57.8885L31 34.56L12.1909 57.8885L28.5653 32.7911L0.566191 22.1115L29.4953 29.9289L31 0Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="iconBtm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="95"
          viewBox="0 0 48 95"
          fill="none"
        >
          <path
            d="M16.2345 3.18698C16.3766 3.3688 16.4405 3.59956 16.4124 3.82858C16.3843 4.0576 16.2664 4.2661 16.0846 4.40817L15.3911 4.94998C15.2092 5.092 14.9785 5.15596 14.7495 5.12783C14.5205 5.0997 14.312 4.98176 14.17 4.79995L11.9413 1.94735C11.7992 1.76553 11.7352 1.53471 11.7633 1.30569C11.7914 1.07667 11.9094 0.868224 12.0912 0.72616L12.7847 0.184351C12.9665 0.0423307 13.1973 -0.0216349 13.4263 0.00649494C13.6553 0.0346248 13.8638 0.152571 14.0058 0.334376L16.2345 3.18698Z"
            fill="#001133"
          />
          <path
            d="M4.24819 12.9355C4.44683 13.1773 4.54129 13.4882 4.51076 13.7996C4.48023 14.1111 4.32723 14.3977 4.0854 14.5963L3.66811 14.939C3.42628 15.1376 3.11543 15.2321 2.80397 15.2016C2.49251 15.171 2.20593 15.018 2.00728 14.7762L0.268205 12.6589C0.06956 12.4171 -0.0248991 12.1063 0.00563174 11.7948C0.0361626 11.4834 0.189163 11.1968 0.430986 10.9981L0.848283 10.6554C1.09011 10.4568 1.40096 10.3623 1.71242 10.3928C2.02388 10.4234 2.31046 10.5764 2.50911 10.8182L4.24819 12.9355Z"
            fill="#001133"
          />
          <path
            d="M42.0815 13.2372C42.1562 13.3184 42.2141 13.4136 42.252 13.5172C42.2899 13.6207 42.3071 13.7307 42.3025 13.8409C42.2979 13.9512 42.2716 14.0594 42.2252 14.1595C42.1787 14.2595 42.113 14.3495 42.0318 14.4241L41.2074 15.1823C41.1262 15.2569 41.0311 15.3149 40.9275 15.3528C40.8239 15.3907 40.7139 15.4078 40.6036 15.4032C40.4934 15.3986 40.3852 15.3723 40.2852 15.3259C40.1851 15.2794 40.0952 15.2137 40.0205 15.1325L37.8545 12.777C37.7798 12.6958 37.7219 12.6007 37.684 12.4971C37.6461 12.3935 37.6289 12.2835 37.6335 12.1733C37.6382 12.0631 37.6644 11.9549 37.7109 11.8548C37.7573 11.7547 37.823 11.6648 37.9042 11.5902L38.7286 10.832C38.8098 10.7574 38.9049 10.6994 39.0085 10.6615C39.1121 10.6236 39.2221 10.6065 39.3323 10.6111C39.4426 10.6157 39.5508 10.642 39.6508 10.6884C39.7509 10.7349 39.8409 10.8005 39.9155 10.8817L42.0815 13.2372Z"
            fill="#001133"
          />
          <path
            d="M30.837 16.8303C31.0422 17.1007 31.1316 17.4416 31.0856 17.7779C31.0395 18.1142 30.8618 18.4185 30.5914 18.6237L30.3204 18.8294C30.05 19.0346 29.7092 19.124 29.3729 19.078C29.0365 19.0319 28.7323 18.854 28.527 18.5836L27.0397 16.6242C26.8345 16.3538 26.7451 16.0129 26.7911 15.6766C26.8372 15.3403 27.015 15.036 27.2853 14.8308L27.5563 14.6251C27.8267 14.4199 28.1675 14.3305 28.5038 14.3766C28.8402 14.4227 29.1444 14.6005 29.3497 14.8709L30.837 16.8303Z"
            fill="#001133"
          />
          <path
            d="M31.4331 34.9691C31.5042 35.05 31.5586 35.144 31.5933 35.246C31.628 35.3479 31.6423 35.4557 31.6354 35.5632C31.6284 35.6706 31.6004 35.7757 31.5528 35.8723C31.5052 35.9689 31.4391 36.0553 31.3582 36.1263L30.7422 36.6675C30.5788 36.8111 30.3651 36.8838 30.1481 36.8698C29.9311 36.8557 29.7286 36.756 29.585 36.5927L12.6229 17.2852C12.5519 17.2043 12.4974 17.1102 12.4627 17.0082C12.4279 16.9063 12.4137 16.7985 12.4207 16.691C12.4276 16.5836 12.4557 16.4786 12.5032 16.382C12.5508 16.2853 12.6169 16.199 12.6978 16.1279L13.3138 15.5867C13.4772 15.4432 13.6909 15.3705 13.9079 15.3845C14.1249 15.3986 14.3275 15.4982 14.471 15.6616L31.4331 34.9691Z"
            fill="#001133"
          />
          <path
            d="M51.5105 21.7052C51.581 21.7849 51.6352 21.8777 51.6698 21.9783C51.7044 22.0789 51.7189 22.1853 51.7124 22.2915C51.7059 22.3977 51.6785 22.5015 51.6319 22.5971C51.5852 22.6927 51.5202 22.7782 51.4405 22.8487L50.5569 23.6306C50.4772 23.7011 50.3844 23.7552 50.2838 23.7898C50.1832 23.8244 50.0768 23.8389 49.9706 23.8324C49.8645 23.8259 49.7606 23.7985 49.665 23.7519C49.5694 23.7052 49.4839 23.6403 49.4134 23.5606L47.2267 21.0891C47.1563 21.0094 47.1022 20.9166 47.0676 20.816C47.0329 20.7154 47.0185 20.609 47.025 20.5029C47.0315 20.3967 47.0588 20.2929 47.1055 20.1973C47.1521 20.1016 47.2171 20.0161 47.2967 19.9457L48.1805 19.1637C48.2601 19.0933 48.3529 19.0392 48.4535 19.0046C48.5541 18.9699 48.6605 18.9555 48.7667 18.962C48.8728 18.9685 48.9767 18.9958 49.0723 19.0425C49.1679 19.0891 49.2534 19.1541 49.3238 19.2338L51.5105 21.7052Z"
            fill="#001133"
          />
          <path
            d="M42.0943 30.6192C42.2416 30.8104 42.3068 31.0522 42.2758 31.2916C42.2447 31.5309 42.1198 31.7481 41.9286 31.8954L41.0412 32.5787C40.85 32.7259 40.6081 32.7912 40.3688 32.7601C40.1295 32.7291 39.9123 32.6042 39.765 32.413L37.7027 29.735C37.5555 29.5438 37.4902 29.3019 37.5212 29.0626C37.5523 28.8232 37.6772 28.606 37.8684 28.4587L38.7558 27.7755C38.947 27.6282 39.1889 27.5629 39.4282 27.594C39.6676 27.6251 39.8847 27.75 40.032 27.9412L42.0943 30.6192Z"
            fill="#001133"
          />
          <path
            d="M19.8584 49.1556C19.9177 49.2224 19.9632 49.3002 19.9925 49.3846C20.0217 49.4689 20.034 49.5583 20.0287 49.6474C20.0234 49.7366 20.0006 49.8238 19.9616 49.9041C19.9226 49.9844 19.8681 50.0563 19.8014 50.1156L19.1582 50.6865C19.0915 50.7458 19.0137 50.7914 18.9293 50.8206C18.8449 50.8498 18.7556 50.8621 18.6665 50.8568C18.5773 50.8516 18.4901 50.8287 18.4098 50.7897C18.3294 50.7507 18.2576 50.6963 18.1983 50.6295L0.378576 30.5586C0.319282 30.4919 0.273707 30.414 0.244481 30.3296C0.215256 30.2452 0.202933 30.156 0.208226 30.0668C0.213519 29.9777 0.236299 29.8904 0.275304 29.8101C0.31431 29.7298 0.368803 29.6579 0.435582 29.5986L1.07865 29.0277C1.14542 28.9684 1.22327 28.9229 1.30765 28.8937C1.39204 28.8644 1.48133 28.8521 1.57047 28.8574C1.65962 28.8627 1.74685 28.8855 1.82718 28.9245C1.90752 28.9635 1.97938 29.0179 2.03867 29.0847L19.8584 49.1556Z"
            fill="#001133"
          />
          <path
            d="M16.8692 35.3408C16.5075 35.6274 15.9984 35.6975 15.4538 35.5357C14.9092 35.3739 14.3737 34.9934 13.9652 34.478C13.7629 34.2227 13.5978 33.942 13.4795 33.6519C13.3611 33.3618 13.2917 33.0679 13.2753 32.7871C13.2589 32.5063 13.2957 32.2439 13.3837 32.0151C13.4718 31.7863 13.6093 31.5955 13.7883 31.4536C14.15 31.167 14.6592 31.0969 15.2038 31.2587C15.7484 31.4205 16.2838 31.801 16.6923 32.3164C16.8946 32.5716 17.0597 32.8523 17.1781 33.1425C17.2964 33.4326 17.3658 33.7264 17.3822 34.0073C17.3986 34.2881 17.3618 34.5504 17.2738 34.7792C17.1857 35.008 17.0482 35.1988 16.8692 35.3408Z"
            fill="#001133"
          />
          <path
            d="M44.339 48.9721C44.4202 49.0686 44.4816 49.18 44.5198 49.3002C44.5579 49.4204 44.5719 49.5469 44.5611 49.6725C44.5504 49.7981 44.515 49.9204 44.4569 50.0323C44.3989 50.1443 44.3194 50.2437 44.223 50.3249L43.4887 50.9432C43.3922 51.0244 43.2808 51.0859 43.1606 51.124C43.0404 51.1621 42.9139 51.1762 42.7883 51.1654C42.6627 51.1546 42.5404 51.1192 42.4285 51.0612C42.3166 51.0032 42.2172 50.9237 42.136 50.8272L40.1778 48.5019C40.0966 48.4055 40.0352 48.294 39.997 48.1739C39.9589 48.0537 39.9449 47.9272 39.9557 47.8016C39.9664 47.6759 40.0018 47.5537 40.0599 47.4417C40.1179 47.3298 40.1974 47.2305 40.2938 47.1493L41.0281 46.5309C41.1245 46.4497 41.236 46.3882 41.3562 46.3501C41.4763 46.312 41.6029 46.2979 41.7285 46.3087C41.8541 46.3195 41.9764 46.3549 42.0883 46.4129C42.2002 46.4709 42.2996 46.5504 42.3808 46.6468L44.339 48.9721Z"
            fill="#001133"
          />
          <path
            d="M46.4188 66.6193C46.59 66.8114 46.6778 67.0636 46.663 67.3204C46.6482 67.5772 46.5319 67.8177 46.3399 67.9888L46.1756 68.1352C45.9835 68.3063 45.7313 68.3942 45.4745 68.3794C45.2177 68.3645 44.9772 68.2483 44.8061 68.0562L27.2174 48.3151C27.0463 48.123 26.9585 47.8708 26.9733 47.614C26.9881 47.3571 27.1044 47.1168 27.2965 46.9456L27.4607 46.7992C27.6527 46.6281 27.9049 46.5403 28.1618 46.5551C28.4186 46.5699 28.659 46.6861 28.8302 46.8782L46.4188 66.6193Z"
            fill="#001133"
          />
          <path
            d="M6.2945 50.3158C6.37258 50.4063 6.43209 50.5112 6.46961 50.6247C6.50713 50.7382 6.52194 50.8579 6.51319 50.9771C6.50443 51.0962 6.47228 51.2126 6.41858 51.3193C6.36488 51.4261 6.29067 51.5212 6.2002 51.5993L5.32209 52.3573C5.23162 52.4354 5.12665 52.4949 5.01319 52.5324C4.89972 52.5699 4.78002 52.5848 4.66083 52.576C4.54165 52.5672 4.42537 52.5351 4.31861 52.4814C4.21185 52.4277 4.11666 52.3535 4.03858 52.263L2.22211 50.1586C2.14402 50.0681 2.08452 49.9631 2.047 49.8497C2.00948 49.7362 1.99467 49.6165 2.00342 49.4973C2.01218 49.3781 2.04433 49.2619 2.09803 49.1551C2.15173 49.0483 2.22594 48.9532 2.31641 48.8751L3.19452 48.1171C3.28499 48.039 3.38996 47.9795 3.50342 47.942C3.61688 47.9044 3.73659 47.8897 3.85578 47.8984C3.97496 47.9072 4.09124 47.9394 4.198 47.9931C4.30476 48.0468 4.39995 48.1209 4.47803 48.2114L6.2945 50.3158Z"
            fill="#001133"
          />
          <path
            d="M18.5739 59.1663C18.6554 59.2642 18.7169 59.3771 18.7548 59.4987C18.7927 59.6204 18.8062 59.7483 18.7947 59.8751C18.7832 60.002 18.7467 60.1254 18.6875 60.2382C18.6283 60.351 18.5474 60.451 18.4495 60.5325L17.6812 61.1726C17.4836 61.3373 17.2287 61.4167 16.9725 61.3934C16.7163 61.3701 16.4798 61.2459 16.3151 61.0483L14.382 58.7281C14.3005 58.6302 14.239 58.5173 14.2011 58.3957C14.1632 58.274 14.1497 58.1461 14.1612 58.0193C14.1728 57.8924 14.2092 57.7691 14.2684 57.6563C14.3276 57.5435 14.4085 57.4434 14.5064 57.3619L15.2747 56.7218C15.4723 56.5572 15.7273 56.4778 15.9835 56.5011C16.2397 56.5244 16.4761 56.6485 16.6408 56.8461L18.5739 59.1663Z"
            fill="#001133"
          />
          <path
            d="M5.98006 65.8539C6.16597 66.0648 6.26052 66.3408 6.24288 66.6214C6.22523 66.902 6.09688 67.1641 5.886 67.35L5.42085 67.76C5.20998 67.9459 4.93391 68.0404 4.65334 68.0228C4.37276 68.0051 4.11068 67.8768 3.92476 67.6659L1.71608 65.1605C1.53017 64.9496 1.43562 64.6735 1.45326 64.393C1.47091 64.1124 1.59933 63.8503 1.8102 63.6644L2.27529 63.2544C2.48616 63.0685 2.76223 62.9739 3.0428 62.9916C3.32337 63.0092 3.58546 63.1377 3.77138 63.3485L5.98006 65.8539Z"
            fill="#001133"
          />
          <path
            d="M30.8858 66.1569C31.0768 66.3878 31.1683 66.6851 31.1401 66.9835C31.1119 67.2818 30.9664 67.5567 30.7355 67.7478L30.3348 68.0793C30.2204 68.1739 30.0886 68.245 29.9467 68.2886C29.8049 68.3323 29.6559 68.3475 29.5081 68.3336C29.3604 68.3196 29.2169 68.2767 29.0857 68.2072C28.9546 68.1378 28.8384 68.0433 28.7438 67.9289L26.8316 65.6173C26.6405 65.3864 26.5491 65.0891 26.5773 64.7907C26.6055 64.4924 26.751 64.2174 26.9819 64.0264L27.3826 63.6949C27.4969 63.6003 27.6288 63.5291 27.7706 63.4855C27.9125 63.4419 28.0615 63.4266 28.2092 63.4406C28.357 63.4545 28.5005 63.4975 28.6317 63.5669C28.7628 63.6363 28.879 63.7309 28.9736 63.8453L30.8858 66.1569Z"
            fill="#001133"
          />
          <path
            d="M19.0803 77.9156C19.1625 78.0095 19.2253 78.1186 19.2653 78.2368C19.3053 78.3549 19.3217 78.4799 19.3134 78.6044C19.3052 78.7289 19.2724 78.8505 19.2171 78.9624C19.1618 79.0742 19.0851 79.174 18.9912 79.2562L18.4344 79.7436C18.2448 79.9096 17.9971 79.9934 17.7457 79.9767C17.4944 79.96 17.2599 79.8442 17.0939 79.6546L14.1561 76.2988C14.0739 76.2049 14.011 76.0958 13.971 75.9776C13.931 75.8594 13.9147 75.7345 13.923 75.6101C13.9313 75.4856 13.964 75.3639 14.0192 75.2521C14.0745 75.1402 14.1513 75.0404 14.2452 74.9582L14.802 74.4708C14.9916 74.3048 15.2393 74.221 15.4907 74.2377C15.7421 74.2544 15.9765 74.3703 16.1425 74.5598L19.0803 77.9156Z"
            fill="#001133"
          />
          <path
            d="M33.9533 92.2138C34.0415 92.3164 34.1087 92.4353 34.1509 92.5638C34.1932 92.6923 34.2097 92.8279 34.1996 92.9627C34.1894 93.0976 34.1528 93.2292 34.0918 93.3499C34.0308 93.4707 33.9466 93.5782 33.8441 93.6664L33.374 94.0707C33.1669 94.2488 32.8975 94.3374 32.6252 94.3169C32.3528 94.2964 32.0996 94.1686 31.9215 93.9615L29.4435 91.0806C29.3553 90.9781 29.2881 90.8592 29.2459 90.7307C29.2036 90.6022 29.1871 90.4666 29.1972 90.3317C29.2074 90.1968 29.244 90.0652 29.305 89.9445C29.366 89.8238 29.4501 89.7162 29.5527 89.628L30.0228 89.2237C30.2299 89.0456 30.4992 88.957 30.7716 88.9775C31.044 88.998 31.2972 89.1258 31.4753 89.3329L33.9533 92.2138Z"
            fill="#001133"
          />
        </svg>
      </div>
      <div className="iconLeft">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="95"
          viewBox="0 0 52 95"
          fill="none"
        >
          <path
            d="M16.2345 3.18698C16.3766 3.3688 16.4405 3.59956 16.4124 3.82858C16.3843 4.0576 16.2664 4.2661 16.0846 4.40817L15.3911 4.94998C15.2092 5.092 14.9785 5.15596 14.7495 5.12783C14.5205 5.0997 14.312 4.98176 14.17 4.79995L11.9413 1.94735C11.7992 1.76553 11.7352 1.53471 11.7633 1.30569C11.7914 1.07667 11.9094 0.868224 12.0912 0.72616L12.7847 0.184351C12.9665 0.0423307 13.1973 -0.0216349 13.4263 0.00649494C13.6553 0.0346248 13.8638 0.152571 14.0058 0.334376L16.2345 3.18698Z"
            fill="#001133"
          />
          <path
            d="M4.24819 12.9355C4.44683 13.1773 4.54129 13.4882 4.51076 13.7996C4.48023 14.1111 4.32723 14.3977 4.0854 14.5963L3.66811 14.939C3.42628 15.1376 3.11543 15.2321 2.80397 15.2016C2.49251 15.171 2.20593 15.018 2.00728 14.7762L0.268205 12.6589C0.06956 12.4171 -0.0248991 12.1063 0.00563174 11.7948C0.0361626 11.4834 0.189163 11.1968 0.430986 10.9981L0.848283 10.6554C1.09011 10.4568 1.40096 10.3623 1.71242 10.3928C2.02388 10.4234 2.31046 10.5764 2.50911 10.8182L4.24819 12.9355Z"
            fill="#001133"
          />
          <path
            d="M42.0815 13.2372C42.1562 13.3184 42.2141 13.4136 42.252 13.5172C42.2899 13.6207 42.3071 13.7307 42.3025 13.8409C42.2979 13.9512 42.2716 14.0594 42.2252 14.1595C42.1787 14.2595 42.113 14.3495 42.0318 14.4241L41.2074 15.1823C41.1262 15.2569 41.0311 15.3149 40.9275 15.3528C40.8239 15.3907 40.7139 15.4078 40.6036 15.4032C40.4934 15.3986 40.3852 15.3723 40.2852 15.3259C40.1851 15.2794 40.0952 15.2137 40.0205 15.1325L37.8545 12.777C37.7798 12.6958 37.7219 12.6007 37.684 12.4971C37.6461 12.3935 37.6289 12.2835 37.6335 12.1733C37.6382 12.0631 37.6644 11.9549 37.7109 11.8548C37.7573 11.7547 37.823 11.6648 37.9042 11.5902L38.7286 10.832C38.8098 10.7574 38.9049 10.6994 39.0085 10.6615C39.1121 10.6236 39.2221 10.6065 39.3323 10.6111C39.4426 10.6157 39.5508 10.642 39.6508 10.6884C39.7509 10.7349 39.8409 10.8005 39.9155 10.8817L42.0815 13.2372Z"
            fill="#001133"
          />
          <path
            d="M30.837 16.8303C31.0422 17.1007 31.1316 17.4416 31.0856 17.7779C31.0395 18.1142 30.8618 18.4185 30.5914 18.6237L30.3204 18.8294C30.05 19.0346 29.7092 19.124 29.3729 19.078C29.0365 19.0319 28.7323 18.854 28.527 18.5836L27.0397 16.6242C26.8345 16.3538 26.7451 16.0129 26.7911 15.6766C26.8372 15.3403 27.015 15.036 27.2853 14.8308L27.5563 14.6251C27.8267 14.4199 28.1675 14.3305 28.5038 14.3766C28.8402 14.4227 29.1444 14.6005 29.3497 14.8709L30.837 16.8303Z"
            fill="#001133"
          />
          <path
            d="M31.4331 34.9691C31.5042 35.05 31.5586 35.144 31.5933 35.246C31.628 35.3479 31.6423 35.4557 31.6354 35.5632C31.6284 35.6706 31.6004 35.7757 31.5528 35.8723C31.5052 35.9689 31.4391 36.0553 31.3582 36.1263L30.7422 36.6675C30.5788 36.8111 30.3651 36.8838 30.1481 36.8698C29.9311 36.8557 29.7286 36.756 29.585 36.5927L12.6229 17.2852C12.5519 17.2043 12.4974 17.1102 12.4627 17.0082C12.4279 16.9063 12.4137 16.7985 12.4207 16.691C12.4276 16.5836 12.4557 16.4786 12.5032 16.382C12.5508 16.2853 12.6169 16.199 12.6978 16.1279L13.3138 15.5867C13.4772 15.4432 13.6909 15.3705 13.9079 15.3845C14.1249 15.3986 14.3275 15.4982 14.471 15.6616L31.4331 34.9691Z"
            fill="#001133"
          />
          <path
            d="M51.5105 21.7052C51.581 21.7849 51.6352 21.8777 51.6698 21.9783C51.7044 22.0789 51.7189 22.1853 51.7124 22.2915C51.7059 22.3977 51.6785 22.5015 51.6319 22.5971C51.5852 22.6927 51.5202 22.7782 51.4405 22.8487L50.5569 23.6306C50.4772 23.7011 50.3844 23.7552 50.2838 23.7898C50.1832 23.8244 50.0768 23.8389 49.9706 23.8324C49.8645 23.8259 49.7606 23.7985 49.665 23.7519C49.5694 23.7052 49.4839 23.6403 49.4134 23.5606L47.2267 21.0891C47.1563 21.0094 47.1022 20.9166 47.0676 20.816C47.0329 20.7154 47.0185 20.609 47.025 20.5029C47.0315 20.3967 47.0588 20.2929 47.1055 20.1973C47.1521 20.1016 47.2171 20.0161 47.2967 19.9457L48.1805 19.1637C48.2601 19.0933 48.3529 19.0392 48.4535 19.0046C48.5541 18.9699 48.6605 18.9555 48.7667 18.962C48.8728 18.9685 48.9767 18.9958 49.0723 19.0425C49.1679 19.0891 49.2534 19.1541 49.3238 19.2338L51.5105 21.7052Z"
            fill="#001133"
          />
          <path
            d="M42.0943 30.6192C42.2416 30.8104 42.3068 31.0522 42.2758 31.2916C42.2447 31.5309 42.1198 31.7481 41.9286 31.8954L41.0412 32.5787C40.85 32.7259 40.6081 32.7912 40.3688 32.7601C40.1295 32.7291 39.9123 32.6042 39.765 32.413L37.7027 29.735C37.5555 29.5438 37.4902 29.3019 37.5212 29.0626C37.5523 28.8232 37.6772 28.606 37.8684 28.4587L38.7558 27.7755C38.947 27.6282 39.1889 27.5629 39.4282 27.594C39.6676 27.6251 39.8847 27.75 40.032 27.9412L42.0943 30.6192Z"
            fill="#001133"
          />
          <path
            d="M19.8584 49.1556C19.9177 49.2224 19.9632 49.3002 19.9925 49.3846C20.0217 49.4689 20.034 49.5583 20.0287 49.6474C20.0234 49.7366 20.0006 49.8238 19.9616 49.9041C19.9226 49.9844 19.8681 50.0563 19.8014 50.1156L19.1582 50.6865C19.0915 50.7458 19.0137 50.7914 18.9293 50.8206C18.8449 50.8498 18.7556 50.8621 18.6665 50.8568C18.5773 50.8516 18.4901 50.8287 18.4098 50.7897C18.3294 50.7507 18.2576 50.6963 18.1983 50.6295L0.378576 30.5586C0.319282 30.4919 0.273707 30.414 0.244481 30.3296C0.215256 30.2452 0.202933 30.156 0.208226 30.0668C0.213519 29.9777 0.236299 29.8904 0.275304 29.8101C0.31431 29.7298 0.368803 29.6579 0.435582 29.5986L1.07865 29.0277C1.14542 28.9684 1.22327 28.9229 1.30765 28.8937C1.39204 28.8644 1.48133 28.8521 1.57047 28.8574C1.65962 28.8627 1.74685 28.8855 1.82718 28.9245C1.90752 28.9635 1.97938 29.0179 2.03867 29.0847L19.8584 49.1556Z"
            fill="#001133"
          />
          <path
            d="M16.8692 35.3408C16.5075 35.6274 15.9984 35.6975 15.4538 35.5357C14.9092 35.3739 14.3737 34.9934 13.9652 34.478C13.7629 34.2227 13.5978 33.942 13.4795 33.6519C13.3611 33.3618 13.2917 33.0679 13.2753 32.7871C13.2589 32.5063 13.2957 32.2439 13.3837 32.0151C13.4718 31.7863 13.6093 31.5955 13.7883 31.4536C14.15 31.167 14.6592 31.0969 15.2038 31.2587C15.7484 31.4205 16.2838 31.801 16.6923 32.3164C16.8946 32.5716 17.0597 32.8523 17.1781 33.1425C17.2964 33.4326 17.3658 33.7264 17.3822 34.0073C17.3986 34.2881 17.3618 34.5504 17.2738 34.7792C17.1857 35.008 17.0482 35.1988 16.8692 35.3408Z"
            fill="#001133"
          />
          <path
            d="M44.339 48.9721C44.4202 49.0686 44.4816 49.18 44.5198 49.3002C44.5579 49.4204 44.5719 49.5469 44.5611 49.6725C44.5504 49.7981 44.515 49.9204 44.4569 50.0323C44.3989 50.1443 44.3194 50.2437 44.223 50.3249L43.4887 50.9432C43.3922 51.0244 43.2808 51.0859 43.1606 51.124C43.0404 51.1621 42.9139 51.1762 42.7883 51.1654C42.6627 51.1546 42.5404 51.1192 42.4285 51.0612C42.3166 51.0032 42.2172 50.9237 42.136 50.8272L40.1778 48.5019C40.0966 48.4055 40.0352 48.294 39.997 48.1739C39.9589 48.0537 39.9449 47.9272 39.9557 47.8016C39.9664 47.6759 40.0018 47.5537 40.0599 47.4417C40.1179 47.3298 40.1974 47.2305 40.2938 47.1493L41.0281 46.5309C41.1245 46.4497 41.236 46.3882 41.3562 46.3501C41.4763 46.312 41.6029 46.2979 41.7285 46.3087C41.8541 46.3195 41.9764 46.3549 42.0883 46.4129C42.2002 46.4709 42.2996 46.5504 42.3808 46.6468L44.339 48.9721Z"
            fill="#001133"
          />
          <path
            d="M46.4188 66.6193C46.59 66.8114 46.6778 67.0636 46.663 67.3204C46.6482 67.5772 46.5319 67.8177 46.3399 67.9888L46.1756 68.1352C45.9835 68.3063 45.7313 68.3942 45.4745 68.3794C45.2177 68.3645 44.9772 68.2483 44.8061 68.0562L27.2174 48.3151C27.0463 48.123 26.9585 47.8708 26.9733 47.614C26.9881 47.3571 27.1044 47.1168 27.2965 46.9456L27.4607 46.7992C27.6527 46.6281 27.9049 46.5403 28.1618 46.5551C28.4186 46.5699 28.659 46.6861 28.8302 46.8782L46.4188 66.6193Z"
            fill="#001133"
          />
          <path
            d="M6.2945 50.3158C6.37258 50.4063 6.43209 50.5112 6.46961 50.6247C6.50713 50.7382 6.52194 50.8579 6.51319 50.9771C6.50443 51.0962 6.47228 51.2126 6.41858 51.3193C6.36488 51.4261 6.29067 51.5212 6.2002 51.5993L5.32209 52.3573C5.23162 52.4354 5.12665 52.4949 5.01319 52.5324C4.89972 52.5699 4.78002 52.5848 4.66083 52.576C4.54165 52.5672 4.42537 52.5351 4.31861 52.4814C4.21185 52.4277 4.11666 52.3535 4.03858 52.263L2.22211 50.1586C2.14402 50.0681 2.08452 49.9631 2.047 49.8497C2.00948 49.7362 1.99467 49.6165 2.00342 49.4973C2.01218 49.3781 2.04433 49.2619 2.09803 49.1551C2.15173 49.0483 2.22594 48.9532 2.31641 48.8751L3.19452 48.1171C3.28499 48.039 3.38996 47.9795 3.50342 47.942C3.61688 47.9044 3.73659 47.8897 3.85578 47.8984C3.97496 47.9072 4.09124 47.9394 4.198 47.9931C4.30476 48.0468 4.39995 48.1209 4.47803 48.2114L6.2945 50.3158Z"
            fill="#001133"
          />
          <path
            d="M18.5739 59.1663C18.6554 59.2642 18.7169 59.3771 18.7548 59.4987C18.7927 59.6204 18.8062 59.7483 18.7947 59.8751C18.7832 60.002 18.7467 60.1254 18.6875 60.2382C18.6283 60.351 18.5474 60.451 18.4495 60.5325L17.6812 61.1726C17.4836 61.3373 17.2287 61.4167 16.9725 61.3934C16.7163 61.3701 16.4798 61.2459 16.3151 61.0483L14.382 58.7281C14.3005 58.6302 14.239 58.5173 14.2011 58.3957C14.1632 58.274 14.1497 58.1461 14.1612 58.0193C14.1728 57.8924 14.2092 57.7691 14.2684 57.6563C14.3276 57.5435 14.4085 57.4434 14.5064 57.3619L15.2747 56.7218C15.4723 56.5572 15.7273 56.4778 15.9835 56.5011C16.2397 56.5244 16.4761 56.6485 16.6408 56.8461L18.5739 59.1663Z"
            fill="#001133"
          />
          <path
            d="M5.98006 65.8539C6.16597 66.0648 6.26052 66.3408 6.24288 66.6214C6.22523 66.902 6.09688 67.1641 5.886 67.35L5.42085 67.76C5.20998 67.9459 4.93391 68.0404 4.65334 68.0228C4.37276 68.0051 4.11068 67.8768 3.92476 67.6659L1.71608 65.1605C1.53017 64.9496 1.43562 64.6735 1.45326 64.393C1.47091 64.1124 1.59933 63.8503 1.8102 63.6644L2.27529 63.2544C2.48616 63.0685 2.76223 62.9739 3.0428 62.9916C3.32337 63.0092 3.58546 63.1377 3.77138 63.3485L5.98006 65.8539Z"
            fill="#001133"
          />
          <path
            d="M30.8858 66.1569C31.0768 66.3878 31.1683 66.6851 31.1401 66.9835C31.1119 67.2818 30.9664 67.5567 30.7355 67.7478L30.3348 68.0793C30.2204 68.1739 30.0886 68.245 29.9467 68.2886C29.8049 68.3323 29.6559 68.3475 29.5081 68.3336C29.3604 68.3196 29.2169 68.2767 29.0857 68.2072C28.9546 68.1378 28.8384 68.0433 28.7438 67.9289L26.8316 65.6173C26.6405 65.3864 26.5491 65.0891 26.5773 64.7907C26.6055 64.4924 26.751 64.2174 26.9819 64.0264L27.3826 63.6949C27.4969 63.6003 27.6288 63.5291 27.7706 63.4855C27.9125 63.4419 28.0615 63.4266 28.2092 63.4406C28.357 63.4545 28.5005 63.4975 28.6317 63.5669C28.7628 63.6363 28.879 63.7309 28.9736 63.8453L30.8858 66.1569Z"
            fill="#001133"
          />
          <path
            d="M19.0803 77.9156C19.1625 78.0095 19.2253 78.1186 19.2653 78.2368C19.3053 78.3549 19.3217 78.4799 19.3134 78.6044C19.3052 78.7289 19.2724 78.8505 19.2171 78.9624C19.1618 79.0742 19.0851 79.174 18.9912 79.2562L18.4344 79.7436C18.2448 79.9096 17.9971 79.9934 17.7457 79.9767C17.4944 79.96 17.2599 79.8442 17.0939 79.6546L14.1561 76.2988C14.0739 76.2049 14.011 76.0958 13.971 75.9776C13.931 75.8594 13.9147 75.7345 13.923 75.6101C13.9313 75.4856 13.964 75.3639 14.0192 75.2521C14.0745 75.1402 14.1513 75.0404 14.2452 74.9582L14.802 74.4708C14.9916 74.3048 15.2393 74.221 15.4907 74.2377C15.7421 74.2544 15.9765 74.3703 16.1425 74.5598L19.0803 77.9156Z"
            fill="#001133"
          />
          <path
            d="M33.9533 92.2138C34.0415 92.3164 34.1087 92.4353 34.1509 92.5638C34.1932 92.6923 34.2097 92.8279 34.1996 92.9627C34.1894 93.0976 34.1528 93.2292 34.0918 93.3499C34.0308 93.4707 33.9466 93.5782 33.8441 93.6664L33.374 94.0707C33.1669 94.2488 32.8975 94.3374 32.6252 94.3169C32.3528 94.2964 32.0996 94.1686 31.9215 93.9615L29.4435 91.0806C29.3553 90.9781 29.2881 90.8592 29.2459 90.7307C29.2036 90.6022 29.1871 90.4666 29.1972 90.3317C29.2074 90.1968 29.244 90.0652 29.305 89.9445C29.366 89.8238 29.4501 89.7162 29.5527 89.628L30.0228 89.2237C30.2299 89.0456 30.4992 88.957 30.7716 88.9775C31.044 88.998 31.2972 89.1258 31.4753 89.3329L33.9533 92.2138Z"
            fill="#001133"
          />
        </svg>
      </div>
      {isMobile ? (
        <Container>
          <QuestionCard
            title={"ما هي طريقة تحميل تطبيق نينجا هوم علي موبايلي؟"}
            description={
              "للتحميل يرجي الذهاب الي ابل ستور او جوجل بلاي والبحث عن (نينجا هوم) أو بزيارة موقعنا علي الانترنت وستجد روابط تحميل التطبيق متوفرة."
            }
          />
          <QuestionCardDark
            title={"لا أجد بالتطبيق صيانة لبعض الأجهزة المنزلية، فما الحل؟"}
            description={
              "نحن نسعي لتقديم خدمة صيانة بجودة عالية لذلك يتوفر بتطبيق نينجا هوم صيانة لبعض الاجهزة المنزلية الرئيسية فقط وسنقوم تدريجياً باضافة صيانة لأجهزة أخري."
            }
          />
          <QuestionCard
            title={"هل متوفر ضمان علي الصيانة بعد عمل الصيانة؟"}
            description={
              "نعم، بكل تأكيد يمكنك طلب الضمان من الفني بعد الانتهاء من الصيانة وقبل مغادرته وسيقوم بتسجيلها في طلبك ويمكنك التواصل مع الفني خلال فترة الضمان."
            }
          />
          <QuestionCardDark
            title={"كيف أقوم بتحديد مكاني لسهولة وصول الفني؟"}
            description={
              "عند تحميلك للتطبيق قم بالسماح للتطبيق بتحديد موقعك وسيقوم التطبيق اتوماتيكيا بالتعرف علي موقعك، وفي حالة أنك تريد الخدمة في موقع اخر قم بتغييرها من الاعدادات."
            }
          />
          <QuestionCard
            title={"كيف أقوم بالدفع؟ وما هي سياسة الالغاء؟"}
            description={
              "بعد انتهاء الفني من عملة وتحديد المبلغ المطلوب يمكنك دفع المبلغ كاملا له وتأكد من اضافة المبلغ في طلبك، وفي حالة الغاء الطلب بعد وصول الفني للعميل وقبل بدأ العمل يدفع العميل 100 جنيه مصاريف انتقال للفني"
            }
          />
          <QuestionCardDark
            title={"أنا فني وأريد العمل في مجال الصيانة معكم؟"}
            description={
              "بكل سهولة يمكنك التسجيل في التطبيق كفني وتحديد انواع الاجهزة والماركات والمدن التي تعمل بها، بعد تفعيل حسابك يمكنك استقبال طلبات الصيانة من العملاء بسهولة."
            }
          />
        </Container>
      ) : (
        <Container>
          <QuestionCard
            title={"ما هي طريقة تحميل تطبيق نينجا هوم علي موبايلي؟"}
            description={
              "للتحميل يرجي الذهاب الي ابل ستور او جوجل بلاي والبحث عن (نينجا هوم) أو بزيارة موقعنا علي الانترنت وستجد روابط تحميل التطبيق متوفرة."
            }
          />
          <QuestionCardDark
            title={"لا أجد بالتطبيق صيانة لبعض الأجهزة المنزلية، فما الحل؟"}
            description={
              "نحن نسعي لتقديم خدمة صيانة بجودة عالية لذلك يتوفر بتطبيق نينجا هوم صيانة لبعض الاجهزة المنزلية الرئيسية فقط وسنقوم تدريجياً باضافة صيانة لأجهزة أخري."
            }
          />
          <QuestionCardDark
            title={"كيف أقوم بتحديد مكاني لسهولة وصول الفني؟"}
            description={
              "عند تحميلك للتطبيق قم بالسماح للتطبيق بتحديد موقعك وسيقوم التطبيق اتوماتيكيا بالتعرف علي موقعك، وفي حالة أنك تريد الخدمة في موقع اخر قم بتغييرها من الاعدادات."
            }
          />
          <QuestionCard
            title={"هل متوفر ضمان علي الصيانة بعد عمل الصيانة؟"}
            description={
              "نعم، بكل تأكيد يمكنك طلب الضمان من الفني بعد الانتهاء من الصيانة وقبل مغادرته وسيقوم بتسجيلها في طلبك ويمكنك التواصل مع الفني خلال فترة الضمان."
            }
          />
          <QuestionCard
            title={"كيف أقوم بالدفع؟ وما هي سياسة الالغاء؟"}
            description={
              "بعد انتهاء الفني من عملة وتحديد المبلغ المطلوب يمكنك دفع المبلغ كاملا له وتأكد من اضافة المبلغ في طلبك، وفي حالة الغاء الطلب بعد وصول الفني للعميل وقبل بدأ العمل يدفع العميل 100 جنيه مصاريف انتقال للفني"
            }
          />
          <QuestionCardDark
            title={"أنا فني وأريد العمل في مجال الصيانة معكم؟"}
            description={
              "بكل سهولة يمكنك التسجيل في التطبيق كفني وتحديد انواع الاجهزة والماركات والمدن التي تعمل بها، بعد تفعيل حسابك يمكنك استقبال طلبات الصيانة من العملاء بسهولة."
            }
          />
        </Container>
      )}
    </div>
  );
};

export default Questions;
