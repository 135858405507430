import React, { useEffect } from 'react'
import './Article.css'
import { Link } from 'react-router-dom'
import articleImg from '../../../../assets/images/articleImg.jfif'
import articleImg2 from '../../../../assets/images/articleInfoImg.jfif'
const Article = () => {

  return (
    <div className="Article">
      <div className="Article_head">
        <div className="Article_head_map">
          <ul>
            <li>
              <Link to={"/"}>الرئيسية</Link>
            </li>
            <li>
              <span>الصيانة</span>
            </li>
            <li>
              <span>اسم الموضوع</span>
            </li>
          </ul>
        </div>
        <div className="Article_head_date">
          <span>14 يناير 2024 12:25 م</span>
        </div>
      </div>
      <div className="article_info">
        <h1 className="article_info_head">
          ممكن هنا نكتب اي عنوان للمقاله في نفس المسافه دي بالظيط
        </h1>
        <div className="article_info_img">
          <img src={articleImg} alt="article_img" />
        </div>
        <div className="article_info_content">
          <p>
            وريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل
            وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم
            إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت
            مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن
            كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم
            تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة
            والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار
            رقائق "ليتراسيت" (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد
            لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل "ألدوس
            بايج مايكر" (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم
            إيبسوم وريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية
            هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان
            لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما
            قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص،
            لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من
            الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في
            الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن
            مع إصدار رقائق "ليتراسيت" (Letraset) البلاستيكية تحوي مقاطع من هذا
            النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل
            "ألدوس بايج مايكر" (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص
            لوريم إيبسوم
          </p>
          <h2>عنوان فرعي</h2>
          <ol>
            <li>وريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي</li>
            <li>وريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي</li>
            <li>وريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي</li>
            <li>وريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي</li>
          </ol>
          <h3>فقره فيها صور</h3>
          <p>
            {" "}
            وريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل
            وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم
            إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت
            مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن
            كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم
            تقضي على{" "}
          </p>
          <div className="article_info_images">
            <div className="article_info_img">
              <img src={articleImg2} alt="article_content_img" />
            </div>
            <div className="article_info_img">
              <img src={articleImg} alt="article_content_img" />
            </div>
          </div>
          <h4>عنوان فرعي</h4>
          وريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل
          وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم
          ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة
          مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب
          بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على
          هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد
          الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق
          "ليتراسيت" (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر
          مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل "ألدوس بايج مايكر"
          (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم وريم
          إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس
          المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم
          ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة
          مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب
          بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على
          هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد
          الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق
          "ليتراسيت" (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر
          مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل "ألدوس بايج مايكر"
          (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم
        </div>
      </div>
      <div className="shareArticle">
        <h2>شارك المقاله علي</h2>
        <ul>
          <li>
            <a href="##">
              <i className="ri-instagram-line"></i>
            </a>
          </li>
          <li>
            <a href="##">
              <i className="ri-youtube-fill"></i>
            </a>
          </li>
          <li>
            <a href="##">
              <i className="ri-linkedin-fill"></i>
            </a>
          </li>
          <li>
            <a href="##">
              <i className="ri-twitter-x-line"></i>
            </a>
          </li>
          <li>
            <a href="##">
              <i className="ri-facebook-circle-fill"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className='slideArticle'>
        <div className='slideArticle_next'>
            <a href='##'>
                <i className="ri-arrow-right-s-line"></i>
                <h2>تعمل ايه لو الفني اللي طالبه جه في وقت غير مناسب</h2>
            </a>
        </div>
        <div className='slideArticle_prev'>
            <a href='##'>
                <h2>تعمل ايه لو الفني اللي طالبه جه في وقت غير مناسب</h2>
                <i className="ri-arrow-left-s-line"></i>
            </a>
        </div>
      </div>
    </div>
  );
}

export default Article