import React from "react";
const FAQ = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      className={(!faq.open ? "faq notopen" : "open faq")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className={ `faq-answer`}>{faq.answer}</div>
    </div>
  );
};

export default FAQ;
