import React, { useContext, useEffect, useRef, useState } from "react";
import MainLayouts from "../../Layouts/MainLayouts";
import { lazy, Suspense } from "react";
import { Container } from "react-bootstrap";
import "./Privacy.css";
import { PagesContext } from "../../contextApi/PagesContext";
import { Helmet } from "react-helmet-async";

// Lazy load the Common component
const Common = lazy(() => import("../../components/commonSection/Common"));

const Privacy = () => {
  const { GetPage, dataPages, loading } = useContext(PagesContext);
  const pageRef = useRef(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      GetPage({ page_id: 1 });
      pageRef.current = false;
    }
  }, [GetPage]);

  if (loading) {
    return <div></div>;
  }

  return (
    <MainLayouts>
      <Helmet>
        <title>نينجا هوم || سياسة الخصوصية</title>
        <meta
          name="description"
          content={
            dataPages?.meta ??
            "Get quick, reliable home device repairs with our intuitive web app. Connect with skilled technicians to fix appliances and gadgets hassle-free."
          }
        />
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <Common title="سياسة الخصوصية" />
      </Suspense>
      <div className="privacyContent py-4">
        <Container>
          {dataPages && dataPages.description ? (
            <div dangerouslySetInnerHTML={{ __html: dataPages.description }} />
          ) : (
            <div>Failed to fetch data</div>
          )}
        </Container>
      </div>
    </MainLayouts>
  );
};

export default Privacy;
